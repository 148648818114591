















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'InfoAboutAffectedCharts'})
export default class InfoAboutAffectedCharts extends Vue {
  @Prop()
  filterName!: string;

  @Prop()
  listItems!: string[];
}
