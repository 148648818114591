






















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'IconWithTooltip'})
export default class IconWithTooltip extends Vue {
  @Prop({required: true})
  icon!: string

  @Prop({
    default: () => ({
      bottom: true,
      color: 'primary'
    })
  })
  tooltipOptions!: Object
}
