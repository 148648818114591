
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {IAnalysisChartSeries} from '@/types/IAnalysisCharts';
import Chart from 'vue-apexcharts';

@Component({
  name: 'AnalysisChart',
  components: {Chart},
})
export default class AnalysisChart extends Vue {
  @Prop()
  series!: IAnalysisChartSeries[];

  @Prop()
  categories!: string | number[];

  @Prop()
  loading!: boolean;

  data: [] = [];
  chartOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      hideOverlappingLabels: true,
      categories: this.categories,
      title: {
        text: 'Data',
      },
    },
    yaxis: {
      title: {
        text: 'Value [EUR]',
      },
    },
  };

  created() {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        hideOverlappingLabels: true,
        categories: this.categories,
        title: {
          text: this.$t('SYSTEM_DATE') as string,
        },
      },
      yaxis: {
        title: {
          text: `${this.$t('SYSTEM_VALUE')} [EUR]`,
        },
      },
    };
  }

  @Watch('categories')
  onCategoriesChanged(newValues: string | number[]) {
    this.setChartCategories(newValues);
  }

  setChartCategories(dates: string | number[]) {
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: {
        // @ts-ignore
        categories: [...dates],
      },
    };
  }
}
